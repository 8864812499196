<template>
    <div class="instructors-container">
        <table class="instructors">
            <thead>
                <th>Instructor</th>
                <th>
                    <button>
                        <span @click.stop="create_instructor()" class="material-symbols-outlined">
                            person_add
                        </span>
                    </button>

                </th>
    
            </thead>
            <tbody>
                <template v-for="instructor in data.instructors">
                    <tr class="instructor" :class="{inactive:!instructor.ordering}">
                        <td>
                            <button @click.stop="toggle_groups(instructor)">
                                <span class="material-symbols-outlined">
                                    expand
                                </span>
                                {{instructor.ui}}                    
                            </button>
                        </td>
                        <td>
                                <button @click.stop="send_to_instructor_dashboard(instructor.user.id)">
                                    <span class="material-symbols-outlined">
                                        preview
                                    </span>
                                </button>
                            
                                <button @click.stop="send_to_hoursheet(instructor.user.id)">
                                    <span class="material-symbols-outlined">
                                        finance_chip
                                    </span>
                                </button>
                            <button @click.stop="show_edit_window(instructor)">
                                <span class="material-symbols-outlined">
                                    edit
                                </span>
                            </button>
                            <button @click.stop="send_hoursheet(instructor)">
                                <span class="material-symbols-outlined">
                                    send_money
                                </span>
                            </button>
                            <button @click.stop="send_credentials(instructor)">
                                <span class="material-symbols-outlined">
                                    key
                                </span>
                            </button> 
                            <button @click.stop="remove_instructor(instructor)">
                                <span class="material-symbols-outlined">
                                    remove
                                </span>
                            </button>



                        </td>
    
                    </tr>
                    
                    <tr>
                        <td  colspan="4">
                            <div class="groups-container" :class="{show_groups:instructor.show_groups, hide_groups:instructor.hide_groups}">
                                <div >
                                    <button @click.stop="create_group(instructor)">
                                        <span class="material-symbols-outlined">
                                            add_circle
                                        </span>
                                    </button>
                                    <button @click.stop="show_addfromlist_window(instructor,'Group')">
                                        <span class="material-symbols-outlined">
                                            playlist_add_check
                                        </span>
                                    </button>
                                </div>
                                <table class="groups">
                                    <thead>
                                        <th>Students</th>
                                        <th>Schedule</th>
                                        <th>Duration</th>
                                        <th>Subject</th>
                                        <th>Comments</th>
                                        <th>Options</th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="group in instructor.groups">
                                            <td class="students-container">
                                                <template v-if="group.student">
                                                    <table class="students">
                                                        <tr class="student" v-for="student in group.student">
                                                            <td>
                                                                {{student.ui}}
                                                            </td>
                                                            <td>{{student.level}}</td>
                                                            <td>
                                                                    <button @click.stop="send_to_student_dashboard(student.user.id)">
                                                                        <span class="material-symbols-outlined">
                                                                            preview
                                                                        </span>
                                                                    </button>
                                                                <button @click.stop="show_payments_window(group,student)">
                                                                    <span class="material-symbols-outlined">
                                                                        lists
                                                                    </span>
                                                                </button> 
                                                                <button @click.stop="show_edit_window(student)">
                                                                    <span class="material-symbols-outlined">
                                                                        edit
                                                                    </span>
                                                                </button> 
    
                                                                <button @click.stop="remove_student(group,student)">
                                                                    <span class="material-symbols-outlined">
                                                                        remove
                                                                    </span>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </template>
                                            </td>
                                            <td>{{group.schedule}}</td>
                                            <td>{{group.duration}}</td>
                                            <td>{{ group.subject }}</td>
                                            <td> {{ group.comments }} </td>
                                            <td> 
    
    
    
                                                <button @click.stop="show_addfromlist_window(group,'Student')">
                                                    <span class="material-symbols-outlined">
                                                        playlist_add_check
                                                    </span>
                                                </button>
    
                                                <button @click.stop="create_student(group)">
                                                    <span class="material-symbols-outlined">
                                                        person_add
                                                    </span>
                                                </button> 
                                                <button @click.stop="show_edit_window(group)">
                                                    <span class="material-symbols-outlined">
                                                        edit
                                                    </span>
                                                </button> 
                                                <button @click.stop="remove_group(instructor,group)">
                                                    <span class="material-symbols-outlined">
                                                        do_not_disturb_on
                                                    </span>
                                                </button>    
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
    
                </template>
            </tbody>
    
        </table>

    </div>

    <Edit/>
    <Delete/>
    <AddFromList/>
    <Payments/>


</template>


<script setup>
    import {ref,reactive,onMounted,onBeforeMount, provide} from 'vue'
    import axios from 'axios'
    import Edit from '@/components/Edit.vue'
    import Delete from '@/components/Delete.vue'
    import AddFromList from '@/components/AddFromList.vue'
    import Payments from '@/components/Payments.vue'

    import {useStore} from 'vuex'

    let store=useStore()

    let data=reactive({
        edit_object:{},
        edit_subobject:{},
        children:{},
        parent:{},
        selected:{},
        payments_student:{},
        student_payments:{},
    })

    provide('data',data)

    onBeforeMount(async()=>{
        
        let response=await axios.post('/admin/get_instructors/')
        data.instructors=response.data
    })


    async function create_instructor(){
        let response=await axios.post('/admin/create_instructor/')
        let new_instructor=response.data
        data.instructors.unshift(new_instructor)

    }

    async function remove_instructor(instructor){

        let confirm_delete=await store.dispatch('confirm_delete',{object:instructor})
        if (!confirm_delete){
            return
        }

        let response=await axios.post('/admin/remove_instructor/',{instructor_id:instructor.id})
                
        for (let instructr of data.instructors){
            if(instructr.id==instructor.id){
                const index=Object.values(data.instructors).indexOf(instructr)
                data.instructors.splice(index,1)
                break
                
            }
        }
        
    }


    async function create_group(instructor){
        let response=await axios.post('/admin/create_group/',{instructor_id:instructor.id})
        // instructor.groups.push(response.data)
        console.log(response)
        let new_group=response.data
        instructor.groups.unshift(new_group)
        // instructor.groups = Object.fromEntries(Object.entries(instructor.groups).sort((a,b)=>a[1].student_ordering-b[1].student_ordering));
    }



    async function remove_group(instructor,group){

        let confirm_delete=await store.dispatch('confirm_delete',{object:group})
        if (!confirm_delete){
            return
        }
        


        let response=await axios.post('/admin/remove_group/',{instructor_id:instructor.id,group_id:group.id})
        console.log(response)


        for(const group_obj of instructor.groups){
            if(group_obj.id==group.id){
                const index=Object.values(instructor.groups).indexOf(group_obj)
                instructor.groups.splice(index,1)
                break
            }
        }


    }


    async function create_student(group){
        let response=await axios.post('/admin/create_student/',{group_id:group.id})
        let new_student=response.data
        group.student.push(new_student)
        // console.log(group.student)

    }

    async function remove_student(group,student){
        let confirm_delete=await store.dispatch('confirm_delete',{object:student})
        if (!confirm_delete){
            return
        }


        let response=await axios.post('/admin/remove_student/',{group_id:group.id,student_id:student.id})
        console.log(student)

        for(let current_student of group.student){
            if(current_student.id==student.id){
                let index=group.student.indexOf(student)
                group.student.splice(index,1)
                break
            }

        }
    }



        
  async function show_edit_window(object){
    
      let response=await axios.post('/admin/get_object/',{object:object})
      Object.assign(object,response.data)
      data.edit_object=object
      data.show_edit_window=true
      data.hide_edit_window=false

  }


  async function show_addfromlist_window(parent,child_endpoint){

      let response =  await axios.post('/admin/get_objects/',{endpoint:child_endpoint})
      data.children=response.data
      
      data.children_endpoint=child_endpoint
      // Object.assign(data.parent,parent)
      data.parent=reactive(parent)
      data.show_addfromlist_window=true
      data.hide_addfromlist_window=false
      
      
      
    let property = child_endpoint.toLowerCase()
      
    console.log('propertying',property);
    if(data.parent.endpoint=='Instructor'){
        property=`${property}s`
    }
    for (let child of data.children){
        for (let ichild of parent[property]){
            if (ichild.id==child.id){
                child.selected=true
            }
        }
    }


  }












    async function toggle_groups(instructor){
        if (instructor.show_groups){
            instructor.hide_groups=true

            setTimeout(() => {
                instructor.show_groups=false
            }, 500);

            // instructor.groups={}
            
        }else{
            if (!instructor.groups){
                instructor.groups={}
            }
            let response=await axios.post('/admin/get_groups/',{instructor_id:instructor.id})
            Object.assign(instructor.groups,response.data)
            instructor.show_groups=true
            instructor.hide_groups=false

        }
    }




    async function show_payments_window(group,student){
        let payments=await axios.post('/payments/get_payments/',{student_id:student.id})
        let instructor_response=await axios.post('/payments/get_plain_instructors/')
        let student_response=await axios.post('/payments/get_plain_students/',{student_id:student.id})
        let group_response=await axios.post('/payments/get_plain_groups/',{student_id:student.id})


        data.emergency_group=group

        data.payment_groups=group_response.data
        data.payment_instructors=instructor_response.data
        data.payment_students=student_response.data
        data.student_payments=payments.data
        
        data.payments_student=student
        data.show_payments_window=true
        data.hide_payments_window=false

        console.log(data.payment_groups)

    }



    function send_to_student_dashboard(student_id){
        window.open('/student-dashboard?user_id='+student_id, '_blank');

    }

    function send_to_instructor_dashboard(instructor_id){
        window.open('/instructor-dashboard?user_id='+instructor_id, '_blank');

    }

    function send_to_hoursheet(instructor_id){
        window.open('/hoursheet?user_id='+instructor_id,'_blank')
    }

async function send_credentials(person){
    let user=person.user
    let response=await axios.post('/admin/send_credentials/',{user_id:user.id})
    if (response.data.status){
        alert(`Credentials message status: ${response.data.status}`)
    }
}

async function send_hoursheet(instructor){
    let response=await axios.post('/admin/send_hoursheet/',{instructor_id:instructor.id})

        alert(`Credentials message status: ${response.data.status}`)
    
}

</script>



<style scoped>

   


    .material-symbols-outlined{
        font-size:x-large;

    }

    .instructors-container{
        width:100%;
        height:fit-content;
        overflow: auto;
    }

    .instructors{
        width: 100%;
        text-align: center;
        border-collapse: collapse;

    }

    .instructor{
        font-size:x-large;
        border-top:5px solid var(--white1);
        border-bottom:5px solid var(--white1);

    }

    .instructor:nth-of-type(4n+3) {
    backdrop-filter:contrast(40%);

    }
    /* .instructor:nth-of-type(odd) {
    backdrop-filter:contrast(40%);

    } */

    .inactive{
        background: var(--gray);
    }


    .groups-container{
        width: 0;
        max-width: 0;
        height: 0;
        max-height: 0;
        font-size: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        background: var(--red);
        color:var(--white);
    
    
    }


    .groups{
        width: 80%;
        overflow: hidden;
    }
 
    .students-container{
        display: flex;
        justify-content: center;
        align-items: center;
        
        min-width: max-content;
        width: 100%;
        max-width: 100%;
    }

    .students{
        width: 100%;
    }

    .student{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


    table td table td{
        padding:5px;
    }
























.show_groups{

animation-name: show_groups;
animation-duration: var(--animation-time);
animation-timing-function: ease-in-out;
/* animation-delay: calc(var(--animation-time)*2); */
/* animation-iteration-count: iteration-count; */
animation-direction: normal;
animation-fill-mode: forwards;

overflow: hidden;

--height:auto;
--width:100%;

--font-size:large;
--padding:5px;
--margin:5px;

}





@keyframes show_groups {
  0%{
    min-width:0%;
    width:0%;
    max-width:0%;
    /* min-height:0%; */
    height:0%;
    max-height:0%;
    font-size:0px;
    padding:0px;
    margin:0px;
  }

  100%{
    min-width:var(--width);
    width:var(--width);
    max-width:var(--width);
    /* min-height:var(--height); */
    height:var(--height) ;
    max-height:var(--height);
    font-size:var(--font-size);
    padding:var(--padding);
    margin:var(--margin);

    
  }

}




.hide_groups{


animation-name: hide_groups;
animation-duration: var(--animation-time);
animation-timing-function: ease-in-out;
/* animation-delay: calc(var(--animation-time)*2); */

animation-direction: normal;
animation-fill-mode: forwards;
}




@keyframes hide_groups {
  0%{
    min-width:var(--width);
    width:var(--width);
    max-width:var(--width);
    /* min-height:var(--height); */
    height:var(--height) ;
    max-height:var(--height);
    font-size:var(--font-size);
    padding:var(--padding);
    margin:var(--margin);
  }

  100%{
    min-width:0%;
    width:0%;
    max-width:0%;
    /* min-height:0%; */
    height:0%;
    max-height:0%;
    font-size:0px;
    padding:0px;
    margin:0px;
  }



  
}



</style>